.featured-container {
  padding-top: 30px;
  margin-left: 5%;
  margin-right: 5%;
  background-color: white;
  border-radius: 10px;
  /* box-shadow: .1rem .1rem .4rem .1rem gray; */

  width: 100%;
  margin: 0 auto;
  max-width: 1370px;
}

.featured-container h1 {
  text-align: center;
  margin-bottom: 8px;
  font-size: 2.5rem;
}

.featured-container .image-container {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.feature-images h2 {
  text-align: start;
  color: black;
  font-family: sans-serif;
  font-weight: 300;
}

.feature-images {
  border-radius: 5px;
  box-shadow: 0.05rem 0.06rem 0.4rem 0.05rem gray;
  width: 25rem;
  /* height: 35rem; */
  position: relative;
  overflow: hidden;
  margin: 1rem auto;
  display: grid;
}

.feature-images:hover {
  box-shadow: 0.08rem 0.09rem 0.7rem 0.08rem rgb(102, 102, 102);
}

.feature-images img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
  /* -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; */
}

.feature-images img:hover {
  opacity: 0.8;
}

.featureCat-icons {
  position: absolute;
  top: -170%;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productDesc {
  align-self: end;
  margin-left: 10px;
  line-height: 22px;
}

.productDesc .shopinfo {
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px dashed #ececec;
  cursor: pointer;
}

.featureCat-icons:hover {
  background-color: rgba(0, 0, 0, 0.479);
  top: 0;
  transition: all 0.8s ease;
}

.featureCat-icons .featureIcons {
  border-radius: 50%;
  background-color: orange;
  color: black;
  font-size: 35px;
  margin: 10px;
  transition: all 0.1s ease;
  padding: 5px;
  cursor: pointer;
}

.featureCat-icons .featureIcons:hover {
  transform: scale(1.1);
  background-color: orange;
  transition: 0.2s;
}

/***************** MEDIA QUERIES ***************/

@media only screen and (max-width: 480px) {
  .feature-images {
    width: 80%;
  }

  .featured-container .image-container {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1161px) {
  .featured-container .image-container {
    flex-wrap: nowrap;
  }

  .feature-images {
    margin: 10px;
  }

  .feature-images img {
    width: 100%;
  }
}
