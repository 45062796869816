.mobmenu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #e8f4e0;
}

.mobmenu-wrapper {
  width: 35%;
}

.mobmenu-wrapper .signin-title {
  text-align: center;
  width: 100%;
}

.mobmenu-wrapper .signin-title .icon {
  font-size: "8rem";
}

.mobmenu-wrapper h1 {
  font-size: 15px;
  font-weight: 300;
}

.mobmenu-wrapper form button {
  background-color: #23763a;
  color: white;
}

.mobmenu-wrapper form .ko {
  color: #23763a;
  cursor: not-allowed;
}

.mobmenu-wrapper form .io {
  color: white;
  cursor: pointer;
}

/********************* MEDIA QUERIES *************************/

@media (max-width: 480px) {
  .mobmenu-wrapper {
    width: 90%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 900px) {
  .mobmenu-wrapper {
    width: 80%;
  }
}
