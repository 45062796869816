.pymtstat-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    }

    .pymtstat-wrapper{
    width:35%;
    }

    .pymtstat-wrapper h1{
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    }


    /********************* MEDIA QUERIES *************************/

    @media (max-width:480px) {
        .pymtstat-wrapper{
            width: 90%;
        }
    }

    @media only screen and (min-width: 480px) and (max-width: 900px){
        .pymtstat-wrapper{
            width: 80%;
        }
    }