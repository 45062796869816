.memaddr-container{
    margin-top: 70px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
}


.memaddr-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}


.memaddr-wrapper h1{
    font-weight: 300;
    text-align: center;
    /* margin-bottom: 20px; */
}

.memaddr-wrapper .top{
display: flex;
align-items: center;
justify-content: space-between;
margin: 10px 0px;
}

.memaddr-wrapper .top .topText{
display: flex;
}


.memaddr-wrapper .bottom{
display: flex;
justify-content: center;
}

.memaddr-wrapper .info{
flex: 3;
}

.memaddr-wrapper .info .product{
display: flex;
justify-content: space-between;
border-bottom: 1px dashed #e1dfdf;
}

.memaddr-wrapper .info .product .productDetail{
flex: 2;
display: flex;
}

.memaddr-wrapper .info .product .productDetail .details{
padding: 20px;
display: flex;
flex-direction: column;
justify-content: space-around; 
}

.memaddr-wrapper .info .product .productDetail .productName{
font-size: 14px;
}

/******************* MEDIA QUERIES ***************************/

@media (max-width:480px) {

    .memaddr-wrapper .bottom{
        flex-direction: column;
    }


    .memaddr-wrapper .info .product{
        flex-direction: column;
        border-bottom: 1px solid #e1dfdf;
        margin-bottom: 10px;
    }

    .memaddr-wrapper .info .product .productDetail{
        flex: 2;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        /* align-content: center; */
        }

}

