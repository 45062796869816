.hot-container {
  background-color: white;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 35px;
  /* box-shadow: .1rem .1rem .4rem .1rem gray; */
  padding-top: 35px;

  width: 100%;
  margin: 0 auto;
  max-width: 1370px;
}

.hot-container h1 {
  margin-bottom: 12px;
  font-size: 2.5rem;
  text-align: center;
}

.hot-container .Hot-images h2 {
  text-align: start;
  color: black;
  font-family: sans-serif;
  font-weight: 400;
  margin-left: 3%;
}

.hot-container .Hot-images {
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: 0.02rem 0.02rem 0.4rem 0.05rem gray;
  width: 80%;
  position: relative;
  overflow: hidden;
  /* background-color: orange; */
  margin: 0 auto;
}

.hot-container .Hot-images:hover {
  box-shadow: 0.08rem 0.09rem 0.7rem 0.08rem rgb(102, 102, 102);
}

.hot-container .Hot-images img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
}

.hot-container .Hot-images img:hover {
  opacity: 0.8;
}

.hot-container .Hot-images .productDesc {
  align-self: end;
  margin-left: 10px;
  line-height: 22px;
}

.hot-container .Hot-images .productDesc .shopinfo {
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px dashed #ececec;
  cursor: pointer;
}

.hotdeals-icons {
  position: absolute;
  top: -170%;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hotdeals-icons:hover {
  background-color: rgba(0, 0, 0, 0.479);
  top: 0;
  transition: all 0.8s ease;
}

.hotdeals-icons .hotIcons {
  border-radius: 50%;
  background-color: orange;
  color: black;
  font-size: 35px;
  margin: 10px;
  transition: all 0.1s ease;
  padding: 5px;
  cursor: pointer;
}

.hotdeals-icons .hotIcons:hover {
  transform: scale(1.1);
  background-color: orange;
  transition: 0.5s;
}

/***************** MEDIA QUERIES ***************/

@media (max-width: 480px) {
  .mobileShowCase {
    display: block;
  }

  .container .showcase {
    display: none;
  }
}
