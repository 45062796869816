
    .addr-wrapper{
    width:35%;
    }

    .addr-wrapper h1{
    font-size: 20px;
    font-weight: 300;
    }
    
    .addr-wrapper form button{
    background-color: #23763a;
    color: white;
    }

    /********************* MEDIA QUERIES *************************/

    @media (max-width:480px) {
        .addr-wrapper{
            width: 100%;
        }
    }

    @media only screen and (min-width: 480px) and (max-width: 900px){
        .addr-wrapper{
            width: 40%;
        }
    }