.all-products-container{
    padding-top: 70px;
    margin-left: 5%;
    margin-right:5%;
    background-color: white;
    border-radius: 10px;
    /* box-shadow: .1rem .1rem .4rem .1rem gray; */

    width: 100%;
    margin: 0 auto 60px auto;
    max-width: 1370px;
}

.all-products-container h1{
    text-align: center;
    margin-bottom: 8px;
    font-size: 2.5rem;
}

.loader-search {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    font-size: 200px;
    top: 30%;
    bottom: 50%;
    left: 50%;
    right: 59%;
    position: absolute;
  }
  .loader-search:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
    background-color: orangered;
    
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

.product-empty {
    margin: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.all-products-wrapper{
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap:wrap;
}

.all-products-container .image-container{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}


.all-product-images h2{
    text-align: start;
    color:black;
    font-family:sans-serif;
    font-weight: 300;
}

.all-product-images {
    border-radius: 5px;
    box-shadow: .05rem .06rem .4rem .05rem gray;
    width: 25rem;
    /* height: 35rem; */
    position: relative; 
    overflow:hidden ;
    margin:1rem auto;
    display: grid;
    margin:10px;
}

.all-product-images:hover {
  box-shadow: 0.08rem 0.09rem 0.7rem 0.08rem rgb(102, 102, 102);
}

.all-product-images img{
    width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;
  /* -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out; */
}

.all-product-images img:hover {
  opacity: 0.8;
}

.all-cat-icons{
    position: absolute;
    top:-170%;
    left: 0;
    bottom:0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center; 
    
}

.all-Productdesc{
    align-self: end;
    margin-left: 10px;
    line-height: 25px;
}

.all-Productdesc .shopinfo {
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px dashed #ececec;
  cursor: pointer;
}


.all-cat-icons:hover{
    background-color: rgba(0, 0, 0, 0.479);
    top: 0;
    transition: all 0.8s ease;
    
}

.all-cat-icons .all-icons{
    border-radius: 50%;
    background-color:orange;
    color: black;
    font-size: 35px;
    margin: 10px;
    transition: all 0.1s ease;
    padding: 5px;
    cursor: pointer;
}

.all-cat-icons .all-icons:hover{
    transform: scale(1.1); 
    background-color: orange;
    transition: .2s;
}


/***************** MEDIA QUERIES ***************/

@media only screen and (max-width: 480px) {
    .all-product-images{
        width: 80%;
    }

    .all-products-container .image-container{
        flex-wrap:wrap;
    }
}

@media only screen and (min-width: 480px) and (max-width: 1161px) {
   .all-products-container .image-container{
       flex-wrap: wrap;
   }

   .all-product-images{
       margin: 10px;
   }

   .all-product-images img{
       width: 100%;
   }
}
