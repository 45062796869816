.Productcontainer {
  margin-top: 20px;
}

.wrapper {
  padding: 5%;
  display: flex;
  gap: 5rem;
}

.ProductImageContainer {
  /* flex: 1; */
}

.ProductImageContainer .imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.ProductImageContainer  .imgWrapper img {
  /* width: 100%;
height: 90vh; */
  object-fit: cover;
  max-width: 500px;
  max-height: 500px;
  /* border: 1px solid red; */
}

.ProductImageContainer .displayItemContainer {
  margin-top: 5px;
  width: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
   /* border: 1px solid #9d2e2e;  */
}

.ProductImageContainer .displayItemContainer .item {
  max-width: 400px;
}

.ProductImageContainer .displayItemContainer .slick-prev:before,
.ProductImageContainer .displayItemContainer .slick-next:before {
  color: gray;
}

.ProductImageContainer .displayItemContainer .slick-slide div {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProductImageContainer .displayItemContainer .slick-slide .image {
  object-fit: cover;
}

.ProductImageContainer
  .displayItemContainer
  .slick-slide.slick-active.slick-current {
  border: 2px solid #73be44;
}

.InfoContainer {
  flex: 1;
  padding: 0px 50px;
  /* border: 1px solid gray; */
}

.InfoContainer h1 {
  font-weight: 300;
  font-size: 25px;
}

.InfoContainer .headerinfo {
  margin: 5px 0px;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgb(136, 135, 135);
  border-bottom: 1px dashed #dcd6d6;
  cursor: pointer;
}

.info-desc {
  margin: 5px 20px 20px 0px;
  font-weight: 300;
  color: rgb(136, 135, 135);
}

.info-desc1 {
  margin: 5px 20px -5px 0px;
  font-weight: 400;
  color: rgb(243, 23, 23);
  font-size: 14px;
}

.price {
  font-weight: 200;
  font-size: 35px;
  display: flex;
  flex-direction: row;
}

.price .pricetag {
  font-weight: 400;
  font-size: 20px;
}

.price .pricetag .oriPrice {
  margin-left: 3px;
  font-size: 12px;
  color: #c1bfbf;
  text-decoration: line-through;
}

.ProductfilterContainer {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 30px 0px;
}

.Productfilter {
  display: flex;
  align-items: center;
}

.filterTitle {
  font-size: 20px;
  font-weight: 200;
}

.filterColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 5px;
  cursor: pointer;
}

.filterSize {
  margin-left: 10px;
  padding: 5px;
}

/* .filterSizeOption{

} */

.addContainer {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  min-width: 200px;
}

.amountContainer {
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: 0px 5px;
  border: 1px solid black;
  font-size: medium;
}

.addContainer button {
  padding: 15px;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
}

.addContainer button:hover {
  background-color: #73be44;
}

.wrapperContainer {
  padding: 0 5% 5% 5%;
  /* padding: 5%; */
  /* border: 2px solid black; */
}

.wrapperContainer h1 {
  font-size: 18px;
  padding: 5px;
  border-bottom: 1px solid #dcd6d6;
}

.wrapperContainer .boxwrapper {
  padding: 5px 10px;
  background-color: #73be44;
  color: #fff;
}

.wrapperContent {
  margin-top: 2rem;
  padding-left: 2.5%;
  display: flex;
  gap: 2rem;
}

.wrapperContent img {
  width: 100%;
  max-width: 1000px;
}

/************************** MEDIA QUERIES *****************************/

@media only screen and (max-width: 480px) {
  .Productcontainer {
    margin-top: 50px;
  }
  .wrapper {
    padding: 10px;
    flex-direction: column;
  }

  .ProductImageContainer img {
    height: 40vh;
  }

  .ProductImageContainer .displayItemContainer {
    width: 330px;
    justify-content: center;
  }

  .ProductImageContainer .displayItemContainer .item {
    max-width: 290px;
  }

  .price {
    flex-direction: column;
  }

  .InfoContainer {
    padding: 10px;
  }

  .ProductfilterContainer {
    width: 100%;
  }

  .addContainer {
    width: 100%;
  }

  .amountContainer {
    padding-left: 10px;
  }

  .wrapperContainer {
    padding: 10px;
  }

  .wrapperContent {
    display: flex;
    gap: 2rem;
  }

  .wrapperContent img {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1161px) {
}
