.refund-container {
  margin-top: 70px;
  margin-left: 5%;
  margin-right: 5%;
}

.refund-empty {
  margin-top: 40px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.refund-empty img {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.refund-wrapper h1 {
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
}

.refund-wrapper .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.refund-wrapper .top .topText {
  display: flex;
}

.refund-wrapper .top .topText h1{
    font-weight: 400;
    color: orangered;
    margin: 0px 50px 0px 10px;
}

.refund-wrapper .top .topText h2 {
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 50px 0px 10px;
}

/* .refund-wrapper .top button{
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
} */

.refund-wrapper .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.refund-wrapper .info {
  flex: 3;
}

.refund-wrapper .info .productContainer {
  margin: 10px 0px;
  border: 1px solid #e1dfdf;
  border-radius: 5px;
}

.refund-wrapper .info .productContainer .productHeader {
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid #e1dfdf; */
  border-bottom: 1px solid #e1dfdf;
  /* background-color: #f8f5f5; */
}

.refund-wrapper .info .productContainer .productHeader .headerinfo {
  margin: 5px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.refund-wrapper .info .product {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgb(176, 246, 176);
}

.refund-wrapper .info .product .return {
  font-size: 15px;
  padding: 5px 20px;
  flex: 1;
}

.refund-wrapper .info .product .return .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
  /* border-bottom: 1px dashed #ececec; */
}

.refund-wrapper .info .product .productDetail {
  flex: 2;
  display: flex;
}

.refund-wrapper .info .product .productDetail img {
  width: 130px;
  height: 130px;
}

.refund-wrapper .info .product .productDetail .details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: flex-start;
align-content: flex-start; */
}

.refund-wrapper .info .product .productDetail .detailremark {
  padding: 3px 20px;
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: flex-start;
align-content: flex-start; */
}

.refund-wrapper .info .product .productDetail .checkBox {
    display: flex;
    align-items: center;
}


.refund-wrapper .info .product .productDetail .checkBox2 {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.refund-wrapper .info .product .productDetail .productName {
  font-size: 15px;
}

.refund-wrapper .info .product .productDetail .productID {
  font-size: 15px;
  display: flex;
  gap: 10px;
}

.refund-wrapper .info .product .productDetail .productColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
}

.refund-wrapper .info .product .productDetail .productSize {
  font-size: 15px;
}

.refund-wrapper .info .product .priceDetail {
  flex: 1;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  background-color: rgb(255, 220, 230);
}

.refund-wrapper .info .product .priceDetail .productAmountContainer {
  display: flex;
  align-items: center;
  /* margin-top: 2px;
  margin-bottom: 8px; */
  margin: 2px 5px 8px 10px;
}


.refund-wrapper .info .product .priceDetail .productAmountContainer .label {
  font-size: 15px;
  margin-right: 5px;
}

.refund-wrapper
  .info
  .product
  .priceDetail
  .productAmountContainer
  .productAmount {
  font-size: 15px;
  margin: 2px 20px;
  font-weight: 500;
}

.refund-wrapper .info .product .priceDetail .productPrice {
  font-size: 15px;
}

.refund-wrapper .summary {
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  min-height: 30vh;
  /* padding: 20px; */
  width: 90vw;
}

.refund-wrapper .summary .summaryTitle {
  font-weight: 200;
  font-size: 24px;
}

.refund-wrapper .summary .summaryItem {
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.refund-wrapper .summary .summaryItemBold {
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 24px;
}

.refund-wrapper .summary .summaryButton {
  width: 100%;
  padding: 10px;
  background-color: #23763a;
  color: white;
  font-weight: 600;
}

/******************* MEDIA QUERIES ***************************/

@media (max-width: 480px) {
  .refund-wrapper .top .topText {
    display: none;
  }

  .refund-wrapper .info .product .return .content {
  flex-direction: column;
}

  .refund-wrapper .bottom {
    flex-direction: column;
  }

  .refund-wrapper .info .product {
    flex-direction: column;
    border-bottom: 1px solid #e1dfdf;
    margin-bottom: 10px;
  }

  .refund-wrapper .info .product .productDetail {
    flex: 2;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* align-content: center; */
  }

  .refund-wrapper
    .info
    .product
    .priceDetail
    .productAmountContainer
    .productAmount {
    margin: 5px 15px;
  }

}
