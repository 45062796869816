/* xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px */

:root {
  --orange: #ff7800;
  --black: #130f40;
  --outline-hover: 0.2rem solid var(--black);
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --light-color: #666;
  --border: 0.2rem solid rgba(0, 0, 0, 0.1);
  --outline: 0.1rem solid rgba(0, 0, 0, 0.1);
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
  scrollbar-color: orange;
}

.containner {
  box-shadow: var(--box-shadow);
  background-color: #fff;
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  top: 0;
  left: 0;
  right: 0;
  /* box-shadow: var(--box-shadow); */
  box-shadow: 0px 12px 15px -12px rgb(223, 220, 220);
  background-color: #fff;
  position: fixed;
  z-index: 1000;
  width: 100%;
  margin: 5px auto;
  max-width: 1370px;
}

.navbar .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .logo-container .logo {
  margin-right: 1.5rem;
  color: orangered;
  cursor: pointer;
}

.navbar .logo-container img {
  margin-right: 1.5rem;
  /* color: orangered; */
  cursor: pointer;
  max-height: 30px;
}

.navbar .huo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.navbar .huo .cart-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  line-height: 25px;
}

.navbar .huo .cart-wrap .cart-text {
  font-size: 15px;
  margin-left: 5px;
}

.navbar .huo .cart-wrap .badge {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .huo .cart-wrap .badge .cartColor {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

/************ MEDIA QUERY ***********/

@media (max-width: 900px) {
  /* .navbar .huo .cart-wrap .cart-text{
        display: none;
    } */
  .navbar .huo {
    display: none;
  }
}

/*############################ SEARCH BAR START #####################*/

.navbar form {
  display: flex;
  justify-content: center;
  width: 40rem;
  height: 3.5rem;
}

.navbar form input {
  width: 100%;
  outline: none;
  border: 2px solid lightgray;
}

.navbar form button {
  border: none;
}

.navbar form .search-icon {
  background-color: #529c66;
  color: white;
  font-size: 3rem;
  transition: all 0.6s ease;
  padding: 5px;
  cursor: pointer;
}

/*###################* SEARCH BAR END ##################################*/

/*###################* NAV LINKS START ##################################*/

.menuNew {
  position: relative;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuNew .menu-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  line-height: 25px;
  /* margin-right: 10px; */
}

.menuNew > .li,
.menuNew > a {
  display: inline-block;
}

.menuNew a {
  color: black;
  display: block;
  margin: 5px 1.5rem;
  font-size: 15px;
}

.menuNew a:hover {
  color: #81c456;
}

.menuNew .menu-wrap .menu-text {
  font-size: 15px;
  margin-left: 5px;
  min-width: 110px;
}

.menuNew .menu-wrap:hover {
  color: #81c456;
}

.menuNew .menu-wrap .menuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.menuNew .menu-wrap .menuIcon1 {
  padding-bottom: 7px;
  margin: 0;
  font-size: 23px;
}

/* .menu 
{
    position: relative;
    font-size: 15px;
    cursor: pointer;
    border:1px solid #ff7800;
}

.menu > .li, .menu > a{
    display: inline-block;
}

.menu a, .logout{
    color: black;
    display: block;
    margin: 5px 1.5rem;
    font-size: 15px;
}


.menu a:hover, .logout:hover {
    color:orangered;
} */

.subMenu {
  position: absolute;
  background-color: white;
  left: 0;
  width: 190px;
  display: none;
  box-shadow: var(--box-shadow);
}

.subMenu .li {
  padding: 10px;
}

.subMenu .li:hover,
.subMenu22 .li:hover {
  color: #73be44;
}

.porel {
  display: flex;
  justify-content: space-between;
}

/* .porel{
    display: flex;
    flex-direction: column;
} */

.subMenu2 {
  position: absolute;
  left: 100%;
  width: 140px;
  top: 0;
  background-color: white;
  display: none;
  box-shadow: var(--box-shadow);
}

.subMenu21 {
  position: absolute;
  left: 65%;
  width: 150px;
  /* top: 50px; */
  background-color: white;
  display: none;
  /* border: 1px solid black; */
  box-shadow: var(--box-shadow);
}

.subMenu21 .li {
  padding: 5px 0;
}

.subMenu22 {
  position: absolute;
  left: 29%;
  width: 200px;
  /* top: 50px; */
  background-color: white;
  display: none;
  /* border: 1px solid black; */
  box-shadow: var(--box-shadow);
}

.subMenu22 .li {
  padding: 3px 0;
}

.subMenu22 .menu-wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  /* color: black; */
  line-height: 25px;
  margin-left: 10px;
}

.subMenu22 .menu-wrap .menuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

/* .menu .li:hover > .subMenu{
    display: block;
}

.menu .li:hover > .subMenu2{
    display: block;
}

.menu .li:hover > .subMenu22{
    display: block;
}

.menu .li:hover > .subMenu21{
    display: block;
} */

.menuNew .li:hover > .subMenu {
  display: block;
}

.menuNew .li:hover > .subMenu2 {
  display: block;
}

.menuNew .li:hover > .subMenu21 {
  display: block;
}

.menuNew .li:hover > .subMenu22 {
  display: block;
}

/************ MEDIA QUERY ***********/

@media (max-width: 900px) {
  /* .menu{
        display: none;
    } */
  .menuNew {
    display: none;
  }
}

/*###################* NAV LINKS END ####################################*/

/*###################*MOBILE VIEW HAMBURGER MENU START###################*/

.menu-barss .icon {
  background: none;
  font-size: 2.5rem;
  margin-left: 3rem;
  margin-top: 10px;
}

.menu-bars {
  display: flex;
  display: none;
}

.navbar .menu-bars .icon {
  background: none;
  font-size: 2.5rem;
  margin-right: 1.5rem;
}

.nav-menu {
  background-color: rgb(255, 255, 255);
  height: calc(100vh - 6.5rem);
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1001;
  left: -100%;
  transition: 850ms;
  overflow-y: scroll;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  font-size: 1.25rem;
}

/************ MEDIA QUERY ***********/

@media (max-width: 900px) {
  .menu-bars {
    display: flex;
  }

  .navbar .logo-container img {
    max-height: 25px;
  }
}

/*###################*MOBILE VIEW HAMBURGER MENU END###################*/

/*###################*MOBILE VIEW START##################################*/

.mob-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* start */
  /* align-items: center; */
  width: 250px;
}

.mob-menu a {
  color: black;
  margin: 5px 1.5rem;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mob-menu a:hover {
  color: #81c456;
}

.mob-subMenu {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.porell {
  display: grid;
}

.mob-subMenu2 {
  background-color: white;
  display: block;
}

.mob-subMenu22 {
  background-color: white;
  display: block;
}

.mob-menu li:hover > .mob-subMenu {
  display: block;
}

.mob-menu li:hover > .mob-subMenu2 {
  display: block;
}

.mob-menu li:hover > .mob-subMenu22 {
  display: block;
}

.cat {
  border-top: 1px solid rgba(255, 166, 0, 0.432);
  /* border-bottom: 2px solid rgba(255, 166, 0, 0.432); */
  font-size: 1.6rem;
  margin-bottom: 0px;
}

.cat a {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
}

.jj {
  margin: 10px 1.5rem;
  font-size: 1.6rem;
  cursor: pointer;
  /* font-size: 2rem; */
}

.juza .kki {
  display: flex;
  justify-content: flex-start; /* start */
  margin: 10px 1.5rem;
  font-size: 1.6rem;
  /* margin-left: 30px; */
}

/*###################**MOBILE VIEW END##################################*/
