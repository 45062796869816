.checkout-container{
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 5%;
}

.checkout-container .checkout-header-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.checkout-container .checkout-header-wrapper .stepper-container{
    width: 45%;
}

.checkout-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.checkout-empty{
    margin-top: 40px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.checkout-empty img{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}


.checkout-wrapper h1{
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
}

.checkout-wrapper .top{
display: flex;
align-items: center;
justify-content: space-between;
margin: 20px 0px;
}

.checkout-wrapper .top .topText{
display: flex;
}

.checkout-wrapper .top .topText h2{
    text-decoration: underline;
    cursor: pointer;
    margin: 0px 50px 0px 10px;
}

.checkout-wrapper .bottom{
display: flex;
justify-content: space-between;
margin-top: 10px;
}

.checkout-wrapper .info{
flex: 3;
}

.checkout-wrapper .info .product{
display: flex;
justify-content: space-between;
border-bottom: 1px dashed #e1dfdf;
}

.checkout-wrapper .info .product .bankDetail {
    flex: 1;
    display: flex;
    align-items: flex-start;
    margin: 20px;
}

.checkout-wrapper .info .product .bankDetail .details {
    /* padding: 0px 20px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items: flex-start;
    align-content: flex-start; */
}

.checkout-wrapper .info .product .bankDetail .details .desc {
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
}

.checkout-wrapper .info .product .bankDetail .details .desc img {
    max-width: 60px;
}

.checkout-wrapper .info .product .productDetail{
flex: 2;
display: flex;
}

.checkout-wrapper .info .product .productDetail img{
width: 150px;
height: 150px;
}

.checkout-wrapper .info .product .productDetail .details{
padding: 20px;
display: flex;
flex-direction: column;
justify-content: space-around; 
/* align-items: flex-start;
align-content: flex-start; */
}

.checkout-wrapper .info .product .productDetail .productName{
font-size: 15px;
}

.checkout-wrapper .info .product .productDetail .productID{
font-size: 15px;
}

.checkout-wrapper .info .product .productDetail .productColor{
width: 20px;
height: 20px;
border-radius: 50%;
background-color: black;
}

.checkout-wrapper .info .product .productDetail .productSize{
font-size: 15px;
}

.checkout-wrapper .info .product .priceDetail{
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.checkout-wrapper .info .product .priceDetail .productAmountContainer{
display: flex;
align-items: center;
margin-bottom: 10px;
}

.checkout-wrapper .info .product .priceDetail .productAmountContainer .productAmount{
font-size: 16px;
margin: 2px 30px;
}

.checkout-wrapper .info .product .priceDetail .productPrice{
font-size: 19px;
font-weight: 300;
}


.checkout-wrapper .summary{
flex: 1;
border:0.5px solid lightgray;
border-radius: 10px;
min-height: 50vh;
padding: 20px;
width: 90vw;
}

.checkout-wrapper .summary .summaryTitle{
font-weight: 200;
font-size: 24px;
}

.checkout-wrapper .summary .summaryItem{
margin: 30px 0px;
display: flex;
justify-content: space-between;
font-size: 15px;
}



.checkout-wrapper .summary .summaryItemBold{
margin: 30px 0px;
display: flex;
justify-content: space-between;
font-weight: 500;
font-size: 24px;
}


.checkout-wrapper .summary .summaryButton{
width: 100%;
padding: 10px;
color: white;
font-weight: 600;
}


/******************* MEDIA QUERIES ***************************/

@media (max-width:480px) {

    .checkout-container .checkout-header-wrapper {
        margin-bottom: 0;
    }

    .checkout-container .checkout-header-wrapper .stepper-container {
        width: 100%;
    }

    /* .checkout-wrapper .top .topText{
        display: none;
    } */

    .checkout-wrapper .bottom{
        flex-direction: column;
    }


    .checkout-wrapper .info .product{
        flex-direction: column;
        border-bottom: 1px solid #e1dfdf;
        margin-bottom: 10px;
    }

    .checkout-wrapper .info .product .productDetail{
        flex: 2;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        /* align-content: center; */
        }

        .checkout-wrapper .info .product .bankDetail {
            margin: 0px 20px;
        }

    .checkout-wrapper .info .product .priceDetail .productAmountContainer .productAmount{
        margin: 5px 15px;
    }

    /* .checkout-wrapper .info .product .priceDetail{
        display: grid;
    } */
}

