.orderdet-container{
    margin-top: 70px;
    margin-left: 5%;
    margin-right: 5%;
}

.orderdet-empty{
    margin-top: 40px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.orderdet-empty img{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}


.orderdet-wrapper h1{
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
}

.orderdet-wrapper .top{
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
}

.orderdet-wrapper .top .topText{
display: flex;
}

.orderdet-wrapper .top .topText h1{
    font-weight: 400;
    color: orangered;
    margin: 0px 50px 0px 10px;
}

/* .orderdet-wrapper .top button{
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
} */

.orderdet-wrapper .bottom{
display: flex;
justify-content: space-between;
margin-top: 10px;
}

.orderdet-wrapper .info{
flex: 3;
}

.orderdet-wrapper .info .product{
display: flex;
justify-content: space-between;
border-bottom: 1px dashed #e1dfdf;
}

.orderdet-wrapper .info .product .productAddress {
    /* flex: 1; */
    display: flex;
    /* justify-content: space-around; */
}

.orderdet-wrapper .info .product .productAddress .details {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.orderdet-wrapper .info .product .productAddress .productName {
    font-size: 14px;
}

.orderdet-wrapper .info .product .productDetail{
flex: 2;
display: flex;
}

.orderdet-wrapper .info .product .productDetail img{
width: 150px;
height: 150px;
}

.orderdet-wrapper .info .product .productDetail .details{
padding: 20px;
display: flex;
flex-direction: column;
justify-content: space-around; 
/* align-items: flex-start;
align-content: flex-start; */
}

.orderdet-wrapper .info .product .productDetail .productName{
font-size: 15px;
}

.orderdet-wrapper .info .product .productDetail .productID{
font-size: 15px;
}

.orderdet-wrapper .info .product .productDetail .shopinfo {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: 1px dashed #ececec;
}

.orderdet-wrapper .info .product .productDetail .productColor{
width: 20px;
height: 20px;
border-radius: 50%;
background-color: black;
}

.orderdet-wrapper .info .product .productDetail .productSize{
font-size: 15px;
}

.orderdet-wrapper .info .product .priceDetail{
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.orderdet-wrapper .info .product .priceDetail .productAmountContainer{
display: flex;
align-items: center;
margin-bottom: 10px;
}

.orderdet-wrapper .info .product .priceDetail .productAmountContainer .productAmount{
font-size: 16px;
margin: 2px 30px;
}

.orderdet-wrapper .info .product .priceDetail .productPrice{
font-size: 19px;
font-weight: 300;
}


.orderdet-wrapper .summary{
flex: 1;
border:0.5px solid lightgray;
border-radius: 10px;
min-height: 50vh;
padding: 20px;
width: 90vw;
}

.orderdet-wrapper .summary .summaryTitle{
font-weight: 200;
font-size: 24px;
}

.orderdet-wrapper .summary .summaryItem{
margin: 30px 0px;
display: flex;
justify-content: space-between;
font-size: 15px;
}



.orderdet-wrapper .summary .summaryItemBold{
margin: 30px 0px;
display: flex;
justify-content: space-between;
font-weight: 500;
font-size: 24px;
}


.orderdet-wrapper .summary .summaryButton{
width: 100%;
padding: 10px;
background-color:#23763a;
color: white;
font-weight: 600;
}


/******************* MEDIA QUERIES ***************************/

@media (max-width:480px) {
    /* .orderdet-wrapper .top .topText{
        display: none;
    } */

    .orderdet-wrapper .bottom{
        flex-direction: column;
    }


    .orderdet-wrapper .info .product{
        flex-direction: column;
        border-bottom: 1px solid #e1dfdf;
        margin-bottom: 10px;
    }

    .orderdet-wrapper .info .product .productDetail{
        flex: 2;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        /* align-content: center; */
        }

    .orderdet-wrapper .info .product .priceDetail .productAmountContainer .productAmount{
        margin: 5px 15px;
    }

    /* .orderdet-wrapper .info .product .priceDetail{
        display: grid;
    } */
}

