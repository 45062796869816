.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #e8f4e0;
    }

    .login-wrapper{
    width:35%;
    }

    .login-wrapper h1{
    font-size: 20px;
    font-weight: 300;
    }
    
    .login-wrapper form button{
    background-color: #23763a;
    color: white;
    }

    .login-wrapper form .ko{
        color: orangered;
        cursor: not-allowed;
    }

    .login-wrapper form .io{
        color: white;
        cursor: pointer;
    }


    /********************* MEDIA QUERIES *************************/

    @media (max-width:480px) {
        .login-wrapper{
            width: 90%;
        }
    }

    @media only screen and (min-width: 480px) and (max-width: 900px){
        .login-wrapper{
            width: 80%;
        }
    }